import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, MenuItem, IconDiv, Seperator, H4 } from './dropdown-menu.styles';
import { faKey, faDoorOpen, faHome, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

const DropdownMenu = ({ handleSignout, setShowMenu, changePass }) => {
    let history = useHistory();
    const handleRedirect = (path) => {
        if(changePass){
            history.push('/profile/password');
            setShowMenu();
        }else {
            history.push(path);
            setShowMenu();
        }
    };

    const handleExit = () =>{
        setShowMenu();
        handleSignout();
    }

    const { pathname } = history.location;
    
    return (
                <Dropdown >
                    <MenuItem onClick={()=>handleRedirect('/dashboard')} pathname={pathname} loc='/dashboard'>
                    <IconDiv><FontAwesomeIcon icon={faHome} /></IconDiv>
                    <H4>מסך ראשי</H4>
                    </MenuItem>
                <Seperator />
                    <MenuItem onClick={()=>handleRedirect('/posts')} pathname={pathname} loc='/posts'>
                    <IconDiv><FontAwesomeIcon icon={faDatabase} /></IconDiv>
                    <H4>ניהול הודעות באתר</H4>
                    </MenuItem>
                <Seperator />
                    <MenuItem onClick={()=>handleRedirect('/profile/password')} pathname={pathname} loc='/profile/password'>
                    <IconDiv><FontAwesomeIcon icon={faKey} /></IconDiv>
                    <H4>החלפת סיסמא</H4>
                    </MenuItem>
                <Seperator/>
                    <MenuItem onClick={handleExit} pathname={pathname} loc={'/'}>
                    <IconDiv><FontAwesomeIcon icon={faDoorOpen}  /></IconDiv>
                    <H4>יציאה ממערכת</H4>
                    </MenuItem>
        </Dropdown>
    )
}

export default DropdownMenu;