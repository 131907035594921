import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUserSlash, faQuestionCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

import { selectSiteUsersError, selectSiteUser } from '../../redux/site-users/site-users.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { startUpdateSiteUser, startDeleteSiteUser } from '../../redux/site-users/site-users.actions';

import { UpdateUserContainer, 
         DivContainer, 
         FormContainer, 
         HGroup, 
         HHGroup, 
         Spacer, 
         ButtonContainer ,
         HeaderDiv,
         ErrorDisplay,
         NotFoundDiv,
         Label, 
         ModalDiv1} from './user-details.styles';
import Checkbox from '../custom-checkbox/custom-checkbox.component';
import FormInput from '../form-input/form-input.component';
import SelectCustomer from '../select-customer/select-customer.component';
import CustomButton from '../custom-button/custom-button.component';


Modal.setAppElement('#modal');

const UpdateUser = (props) => {
    const { backendError, siteUser, currentUser, startSiteUserUpdate, startDeleteSiteUser} = props;
    const [siteUserDetails, setSiteUserDetails] = useState({});
    const [ selectedCustomer, setSelectedCustomer] = useState({label: '', value: ''});
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const modalStyles = {
        content : {
            top                   : '50%',
            left                  : '30%',
            right                 : '30%',
            bottom                : 'auto',
            // marginRight           : '-50%',
            transform             : 'translate(0%, -50%)',
            backgroundColor: '#f9d2d2',
            border: '1px dotted black',
          }
    }

    useEffect(()=>{
        setSiteUserDetails(siteUser)
        setSelectedCustomer({ label: siteUser.customerName, value: siteUser.customerNumber})
    },[siteUser]);

    const {
            // UserID,
            cellPhone,
            createDate,
            // createdAt,
            createdBy,
            customerName,
            customerNumber,
            email,
            firstName,
            fullName,
            isActive,
            // isAdmin,
            // isOnline,
            lastName,
            type,
            error,
            changePassword
            } = siteUserDetails;

    const handleSelectCustomer = (customer) => {
        setSelectedCustomer({label: customer ? customer.label : '', value: customer ? customer.value : 0 })
        setSiteUserDetails({
            ...siteUserDetails, 
            customerNumber: customer? customer.value : 0
        });
    }

    const handleChange = (event) => {
        const {name, value } = event.target;
        setSiteUserDetails({ ...siteUserDetails, [name]: value});

    }
    const handleChecked = (event) => {
        const { name, checked } = event.target;
        setSiteUserDetails({...siteUserDetails, [name]: checked });
    }

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };
    // const handleError = error => setSiteUserDetails({...siteUserDetails, error: error});
    // const handleClearError = () => setSiteUserDetails({...siteUserDetails, error: null});


    
    const handleSubmit = (event) => {
        event.preventDefault();
        const values = ['firstName', 'lastName', 'isActive', 'customerNumber','changePassword']
        let updatedSiteUser = {};
        values.forEach(value => {
            if(siteUser[value] !== siteUserDetails[value]){ updatedSiteUser[value]=siteUserDetails[value] }
        });
        // console.log(updatedSiteUser)
        startSiteUserUpdate({userUpdates: updatedSiteUser}, siteUser.UserID);

    };
    const handleDelete = () => {
        startDeleteSiteUser(siteUser.UserID, siteUser.email);
    }
    let siteUserExists = siteUser.UserID !== undefined;

    const OtherInputs = () => (
        currentUser.UserID !== siteUser.UserID ?
        <div>
        <HHGroup>
        <HGroup>
        <div>
            <Label isActive={isActive}>
            <span>משתמש {isActive? '' : 'לא'} פעיל</span>
            <Checkbox checked={isActive || false } onChange={handleChecked} name='isActive'/>
            </Label>
        </div>
        </HGroup>
        <Spacer />
        <HGroup>
        <div>
            <Label isActive={changePassword}>
            <span>אילוץ החלפת סיסמא : {changePassword ? 'פעיל' : 'לא פעיל'}</span>
            <Checkbox checked={changePassword || false } onChange={handleChecked} name='changePassword'/>
            </Label>
        </div>
        </HGroup>
        </HHGroup>
        </div>
        : 
        <div>
            <Label isActive={isActive}>
            <span>משתמש {isActive? '' : 'לא'} פעיל</span>
            <Checkbox checked={isActive || false } onChange={handleChecked} name='isActive'/>
            </Label>
        </div>
    );

    return (
        
        <DivContainer>
            {
                siteUserExists && 

            <UpdateUserContainer>
                <HeaderDiv>
                <div>
                    <h4><span>: פרטי משתמש</span></h4>
                </div>
                <div style={{width: '5px'}} />
                <div style={{color: 'lightsalmon'}}>
                    <h4><span>{fullName}</span></h4>
                </div>
                <div style={{width: '30px'}} />
                <div>
                    {
                        customerName !== '' && <h4><span>: שייך ללקוח</span></h4>
                    }
                    
                </div>
                <div style={{width: '5px'}} />
                <div style={{color: 'lightsalmon'}}>
                    <h4><span>{customerName}</span></h4>
                </div>
                </HeaderDiv>
                <FormContainer>
                    <form onSubmit={handleSubmit}>
                            <HHGroup>
                            <HGroup>
                            <FormInput 
                            label='שם פרטי'
                            type='text' 
                            name='firstName' 
                            value={firstName || ''}
                            onChange={handleChange} 
                            dir='rtl'
                            role='labelOpen'
                            />
                            </HGroup>
                            <Spacer />
                            <HGroup>
                            <FormInput 
                            label='שם משפחה'
                            type='text' 
                            name='lastName' 
                            value={lastName || ''}
                            onChange={handleChange} 
                            dir='rtl'
                            role='labelOpen'
                            />
                            </HGroup>
                            </HHGroup>   
                            <FormInput 
                            label='כתובת מייל'
                            type='email' 
                            name='email' 
                            value={email || ''}
                            onChange={handleChange} 
                            disabled='disabled'
                            role='labelOpen'
                            />
                            <FormInput 
                            label='טלפון נייד'
                            type='tel' 
                            pattern="[0-9]{10}"
                            name='cellPhone' 
                            value={cellPhone || ''}
                            onChange={handleChange} 
                            placeholder='##########'
                            disabled='dsiabled'
                            role='labelOpen'
                            />
                            <HHGroup>
                            <HGroup>
                            <SelectCustomer 
                              label='לקוח'
                              optionValue={selectedCustomer}
                              setOptionValue={handleSelectCustomer}
                            
                            />
                            </HGroup>
                            <Spacer />
                            <HGroup>
                            <FormInput 
                            label='מספר לקוח'
                            type='number' 
                            name='customerNumber' 
                            value={customerNumber || 0}
                            onChange={handleChange} 
                            role='labelOpen'
                            disabled='disabled'
                            />
                            </HGroup>
                            </HHGroup>
                            <FormInput 
                            label='סוג משתמש'
                            type='text' 
                            name='type' 
                            value={type || ''}
                            onChange={handleChange} 
                            role='labelOpen'
                            disabled='disabled'
                            dir='rtl'
                            />
                            <HHGroup>
                            <HGroup>
                            <FormInput 
                            label='הוקם בתאריך'
                            type='text' 
                            name='createDate' 
                            value={(new Date(createDate)).toLocaleDateString('en-GB') || ''}
                            onChange={handleChange} 
                            role='labelOpen'
                            disabled='disabled'
                            dir='rtl'
                            />
                            </HGroup>
                            <Spacer />
                            <HGroup>
                            <FormInput 
                            label='הוקם ע"י'
                            type='text' 
                            name='createdBy' 
                            value={createdBy || ''}
                            onChange={handleChange} 
                            role='labelOpen'
                            disabled='disabled'
                            dir='rtl'
                            />
                            </HGroup>
                            </HHGroup>
                            <OtherInputs />                            
                            {   
                                error && <ErrorDisplay>
                                <span>{error}</span>
                                </ErrorDisplay>
                            }
                            {
                                backendError && <ErrorDisplay>
                                <span>{backendError}</span>
                                </ErrorDisplay>
                            }    

                            <ButtonContainer>
                                <CustomButton type='submit' inverted >שמירה<FontAwesomeIcon icon={faSave}/></CustomButton>
                               <CustomButton type='button' onClick={toggleModal} deleteButton>מחיקה<FontAwesomeIcon icon={faUserSlash} /></CustomButton>
                            </ButtonContainer>
                            <Spacer />
                    </form>
                </FormContainer>
            </UpdateUserContainer>
        }
        {
            !siteUserExists && 
            <NotFoundDiv>
            <h3>לא נמצא משתמש להצגה</h3>
            </NotFoundDiv>

        }
        <Modal isOpen= {isModalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={toggleModal}
        style={modalStyles}
        >
        <DivContainer>
        <div dir='rtl' style={{display: 'flex', 
                                flexDirection: 'row',
                                alignItems: 'center', 
                                justifyContent: 'space-between',
                                marginBottom: '20px',
                                }} >
            <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '50px'}}/>
            <div style= {{width: '10px'}}></div>
            <h4 dir='rtl'>האם ברצונך למחוק את המשתמש ?</h4>
            </div>
            <ModalDiv1>
            <CustomButton onClick={toggleModal} inverted>ביטול<FontAwesomeIcon icon={faWindowClose} /></CustomButton>
            <CustomButton onClick={handleDelete} deleteButton>מחיקה<FontAwesomeIcon icon={faUserSlash}/></CustomButton>
            </ModalDiv1>
        </DivContainer>
        </Modal>
        </DivContainer>
    )
};

const mapStateToProps = createStructuredSelector({
    backendError: selectSiteUsersError,
    currentUser: selectCurrentUser,
    siteUser: selectSiteUser
});

const mapDispatchToProps = dispatch => ({
    startSiteUserUpdate: (updateDetails, userId) => dispatch(startUpdateSiteUser(updateDetails, userId)),
    startDeleteSiteUser: (userId, userEmail) => dispatch(startDeleteSiteUser(userId,userEmail))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);