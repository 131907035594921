import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faSave} from '@fortawesome/free-solid-svg-icons';

import { startCreateNewUser, cleaError } from '../../redux/site-users/site-users.actions';
import { selectSiteUsersError } from '../../redux/site-users/site-users.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import { CreateNewUserContainer, 
         DivContainer, 
         FormContainer, 
         HGroup, 
         HHGroup, 
         Spacer, 
         ButtonContainer , 
         ErrorDisplay,
         HeaderDiv } from './create-user.styles';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import SelectCustomer from '../select-customer/select-customer.component';

const CreateNewUser = ({ backendError, currentUser, startCreateNewUser, clearBackendError, setTabIndex }) => {
    const INITIAL_STATE = { 
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        customerNumber: '',
        phone: '',
        customerType: '',
        error: null,
        selectCustomer: null
    }   
    const [ newUserDetails, setNewUserDetails ] = useState(INITIAL_STATE);
    const {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        customerNumber,
        phone,
        customerType,
        error,
        selectCustomer
    } = newUserDetails;

    const customerTypesList = [
        {
            label: 'לקוח',
            value: 1,
        },
        {
            label: 'עובד',
            value: 2,
        }
    ]

    
    const handleCancel = () => {
        setTabIndex(0);
    }

    const handleChange = (event) => {
        const {name, value } = event.target;
            setNewUserDetails({...newUserDetails, [name]: value})
    }

    const handleCustomerTypeSelected = (optionValue) => {
        setNewUserDetails({
            ...newUserDetails, 
            customerType: optionValue ? optionValue.label : ''
        })
    }
    
    const handleError = error => setNewUserDetails({...newUserDetails, error: error});
    const handleClearError = () => {
        setNewUserDetails({...newUserDetails, error: null});
        clearBackendError();

    };

    const isPassValid = password.match(/[0-9a-zA-Z]{8,}/) && password === confirmPassword;

    const handleSelectCusotomer = optionValue => setNewUserDetails(
        {
            ...newUserDetails,
            selectCustomer: optionValue, 
            customerNumber: optionValue ? optionValue.value : 0
        });
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if(!isPassValid) {
            return handleError('כל השדות בטופס הם שדות חובה, יש לבדוק שכל השדות מולאו נכון ולנסות שוב');
        }
        let userDetailsFixed = {
            firstName,
            lastName,
            email,
            password,
            phone,
            customerNumber,
            createdBy: currentUser.UserID            
        }
        if(customerType === 'עובד') {
            userDetailsFixed.isAdmin = true;
        }
        startCreateNewUser(userDetailsFixed);
        setNewUserDetails({...INITIAL_STATE});
    };
    
    return (
        <DivContainer>
            <CreateNewUserContainer>
            <HeaderDiv>
                <h4 style={{color: 'lightsalmon'}}>הקמת משתמש חדש</h4>
            </HeaderDiv>
                <FormContainer>
                    <form onSubmit={handleSubmit}>
                        <HHGroup>
                        <HGroup>
                        <FormInput 
                            name='firstName' 
                            type='text' 
                            value={firstName} 
                            handleChange={handleChange}
                            label='שם פרטי'
                            required
                            dir='rtl'
                            onFocus={handleClearError}
                            />
                            </HGroup>
                            <Spacer />
                            <HGroup>
                            <FormInput 
                            name='lastName' 
                            type='text' 
                            value={lastName} 
                            handleChange={handleChange}
                            label='שם משפחה'
                            required
                            dir='rtl'
                            onFocus={handleClearError}
                            />
                            </HGroup>
                            </HHGroup>
                            <FormInput 
                            name='email' 
                            type='email' 
                            value={email} 
                            handleChange={handleChange}
                            label='כתובת מייל'
                            required
                            onFocus={handleClearError}
                            />
                            <FormInput 
                            name='password' 
                            type='password' 
                            value={password} 
                            handleChange={handleChange}
                            label='סיסמא'
                            required
                            onFocus={handleClearError}
                            pattern=".{8,}"
                            title="מינימום 8 תוים"
                            />
                            <FormInput 
                            name='confirmPassword' 
                            type='password' 
                            value={confirmPassword} 
                            handleChange={handleChange}
                            label='אישור סיסמא'
                            required
                            onFocus={handleClearError}
                            pattern=".{8,}"
                            title="מינימום 8 תוים"
                            />
                            <HHGroup>
                            <HGroup>
                                <SelectCustomer 
                                label='לקוח'
                                optionValue={selectCustomer} 
                                setOptionValue={handleSelectCusotomer}
                                />
                            </HGroup>
                            <Spacer />
                            <HGroup>
                            <FormInput 
                                name='customerNumber' 
                                type='number' 
                                value={customerNumber} 
                                handleChange={handleChange}
                                label='מספר לקוח'
                                min={1}
                                max={99999}
                                required
                                onFocus={handleClearError}
                                role='labelOpen'
                                disabled='disabled'
                                />
                            </HGroup>
                            </HHGroup>
                            <SelectCustomer 
                            label='בחר סוג משתמש'
                            options={customerTypesList}
                            optionValue={customerType} 
                            setOptionValue={handleCustomerTypeSelected}
                            />
                            <FormInput 
                            name='phone' 
                            type='tel' 
                            pattern="[0-9]{10}"
                            value={phone} 
                            handleChange={handleChange}
                            label='טלפון נייד'
                            required
                            placeholder='##########'
                            onFocus={handleClearError}
                            />
                            <div onFocus={handleClearError} >
                            </div>
                            <div style={{marginTop: '25px'}} ></div>
                            <ButtonContainer>
                                <CustomButton type='submit' inverted>שמירה<FontAwesomeIcon icon={faSave} /></CustomButton>
                                <CustomButton type='button' onClick={handleCancel} inverted>ביטול<FontAwesomeIcon icon={faWindowClose} /></CustomButton>
                            </ButtonContainer>
                            {   
                                error && <ErrorDisplay>
                                <span>{error}</span>
                                </ErrorDisplay>
                            }
                            {
                                backendError && <ErrorDisplay>
                                <span>{backendError}</span>
                                </ErrorDisplay>
                            }    
                    </form>
                </FormContainer>
            </CreateNewUserContainer>
        </DivContainer>
    )
};

const mapStateToProps = createStructuredSelector({
    backendError: selectSiteUsersError,
    currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
    startCreateNewUser: (userDetails, history) => dispatch(startCreateNewUser(userDetails, history)),
    clearBackendError: () => dispatch(cleaError())
});

export default connect(mapStateToProps,mapDispatchToProps)(CreateNewUser);