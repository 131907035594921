import userActionTypes from './user.types';

const INITIAL_STATE = {
    currentUser: null,
    error: null,
    isFetching: false
};

const userReducer = (state = INITIAL_STATE, action) => {
        switch(action.type) {
            case userActionTypes.SIGN_IN_START:
            case userActionTypes.SIGN_OUT_START:
            case userActionTypes.UPDATE_PASSWORD_START:
            return{
                ...state,
                error: null,
                isFetching: true

            }
        case userActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                isFetching: false
            }
        case userActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                currentUser: null,
                error: null,
                isFetching: false
            }
        case userActionTypes.SIGN_IN_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }
        case userActionTypes.SIGN_OUT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }
        case userActionTypes.SET_USER_ERROR:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }
        case userActionTypes.CLEAR_USER_ERROR:
            return {
                ...state,
                error: null,
                isFetching: false
            }
        case userActionTypes.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                currentUser: {...state.currentUser,...action.payload},
                error:null,
                isFetching: false
            }
        case userActionTypes.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }
        default:
            return state;
    }
}

export default userReducer;