import React, { useState } from 'react';

import { Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import './user-details.styles.scss';

import UpdateUser from './update-user.component';
import ChangeUserPassword from '../cahnge-user-password/change-user-password.component';
import UserUsageLog from '../user-usage-log/user-usage-log.component';
import CreateUser from '../create-user/create-user.component';


const UserDetails = () => {
    const [tabIndex, setTabIndex] = useState(0);
    
    return (
    <div className='tab-container' id='users-tab'>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
                <Tab>עדכון פרטי משתמש</Tab>
                <Tab>החלפת סיסמא למשתמש</Tab>
                <Tab>יומן כניסות ויציאות</Tab>
                <Tab>הקמת משתמש חדש</Tab>
            </TabList>

            <TabPanel>
                <UpdateUser />
            </TabPanel>
            <TabPanel>
                <ChangeUserPassword />
            </TabPanel>
            <TabPanel>
                <UserUsageLog />
            </TabPanel>
            <TabPanel>
            <CreateUser setTabIndex={setTabIndex}/>
            </TabPanel>

        </Tabs>
    </div>
    );
};



export default UserDetails;