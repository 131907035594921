import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    user => user.currentUser
);

export const selectUserAuth = createSelector(
    [selectCurrentUser],
    currentUser => currentUser.auth
);
export const selectUserError = createSelector(
    [selectUser],
    user => user.error
);

export const selectChangePassword = createSelector(
    [selectCurrentUser],
    currentUser => currentUser.ChangePassword
)

export const selectUserEmail = createSelector(
    [selectCurrentUser],
    currentUser => currentUser.Email
)