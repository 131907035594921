import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {default as PostsTableContainer} from '../../components/posts-table/posts-table.container';
import './posts.styles.scss';
import { Container, 
          PageHeader, 
          MainDiv, 
          RightPane, 
          LeftPane, 
          PostsContainer,
          FormDiv
         } from './posts.styles';
import PostForm from '../../components/post-form/post-form.component';

const PostsPage = () => {
  const [postsTabIndex, setPostsTabIndex] = useState(0);
    return (
        <Container>
            <PageHeader>
              <h2>ניהול הודעות באתר</h2>
            </PageHeader>
          <MainDiv>
            <RightPane>
              <PostsContainer>
                <PostsTableContainer />
              </PostsContainer>
            </RightPane>
            <LeftPane>
            <div className='posts-tab-container' id='posts-tab'>
              <Tabs selectedIndex={postsTabIndex} onSelect={index => setPostsTabIndex(index)}>
              <TabList>
                <Tab>עריכת הודעה</Tab>
                <Tab>הודעה חדשה</Tab>
              </TabList>
              <TabPanel>
                <FormDiv>
                  <PostForm setPostsTabIndex={setPostsTabIndex} isUpdate={true} />
                </FormDiv>
              </TabPanel>
              <TabPanel>
                <FormDiv>
                  <PostForm />
                </FormDiv>
              </TabPanel>
              </Tabs>
            </div>
            </LeftPane>
          </MainDiv>
        </Container>
    )
};

export default PostsPage;