import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faCommentSlash, faWindowClose, faQuestionCircle, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

import { selectCurrentPost, selectPostsError } from '../../redux/posts/posts.selectors'
import { startUpdatePost, startDeletePost, startAddPost, clearPostsError } from '../../redux/posts/posts-actions';

import { PostFormContainer, Form, Label,ButtonContainer, ModalDiv1, DivContainer } from './post-form.styles';
import FormInput from '../form-input/form-input.component';
import Checkbox from '../custom-checkbox/custom-checkbox.component';
import CustomButton from '../custom-button/custom-button.component';

Modal.setAppElement('#modal')
const PostForm = ({ isUpdate, setPostsTabIndex, currentPost, updatePost, deletePost, addNewPost, error, clearPostsError }) => {
    
    const INITIAL_POST = {
        id: 0,
        msgId: '',
        title: '',
        body: '',
        isActive: true,
        d: '',
        createdBy: ''
    }
    
    const [post, setPost] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const { id, createdBy, d, title, body, isActive = true } = post;

    useEffect(()=> {
        if(isUpdate){
            setPost({...currentPost});
        }
    },[isUpdate, currentPost]);
    
    const handleCancel = ()=> {
        setPost({...INITIAL_POST});
        // setPostsTabIndex(0);
    };
    
    const handleClearError = ()=> {
        if(error) {
            clearPostsError();
        }   
    }
    const handleChange = (event) => {
        const {name, value } = event.target;
        setPost({ ...post, [name]: value});
    }

    const handleChecked = (event) => {
        const { name, checked } = event.target;
        setPost({...post, [name]: checked });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //Add new Post
        if(!isUpdate) {
            const newPost = {
                title: title,
                body: body
            }
            addNewPost(newPost);
            handleCancel();
        } else {
            const updatedPost = {
                title: title,
                body: body,
                isActive: isActive
            }
            updatePost(id,updatedPost);
        };
    };

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };

    const handleDelete = () => {
        let postId = currentPost.id
        deletePost(postId);
        toggleModal();
    }

    const modalStyles = {
        content : {
            top                   : '50%',
            left                  : '30%',
            right                 : '30%',
            bottom                : 'auto',
            // marginRight           : '-50%',
            transform             : 'translate(0%, -50%)',
            backgroundColor: '#f9d2d2',
            border: '1px dotted black',
          }
    }

    return (
        <PostFormContainer>
        <div id="modal2"></div>
        { ((isUpdate && currentPost) || !isUpdate) &&
         <Form onSubmit={handleSubmit}>
         { isUpdate && <div>
            <FormInput 
            label='יוצר ההודעה'
            type='text' 
            name='createdBy' 
            value={createdBy || ''}
            onChange={handleChange} 
            onFocus={handleClearError}
            dir='rtl'
            role='labelOpen'
            disabled
            />
            <FormInput 
            label='תאריך יצירה'
            type='text' 
            name='d' 
            value={d || ''}
            onChange={handleChange} 
            dir='rtl'
            role='labelOpen'
            disabled
            />
            </div>
            }
            <FormInput 
            label='כותרת ההודעה'
            type='text' 
            name='title' 
            value={title || ''}
            onChange={handleChange} 
            dir='rtl'
            role={isUpdate && 'labelOpen'}
            onFocus={handleClearError}
            required
            />
            <FormInput 
            label='תוכן ההודעה'
            type='textarea' 
            name='body' 
            value={body || ''}
            onChange={handleChange} 
            dir='rtl'
            role={isUpdate && 'labelOpen'}
            onFocus={handleClearError}
            required
            />
            {isUpdate &&
            <div>
                <Label isActive={isActive}>
                <span>הודעה {isActive? '' : 'לא'} פעילה</span>
                <Checkbox checked={isActive || false } onChange={handleChecked} name='isActive'/>
                </Label>
            </div>
            }
            <ButtonContainer>
                <CustomButton type='submit' inverted ><FontAwesomeIcon icon={faSave}/>שמירה</CustomButton>
                {isUpdate && <CustomButton type='button' onClick={toggleModal} deleteButton><FontAwesomeIcon icon={faCommentSlash} />מחיקה</CustomButton>}
                {!isUpdate && <CustomButton type='button' onClick={handleCancel}><FontAwesomeIcon icon={faWindowClose} />ביטול</CustomButton>}
            </ButtonContainer>
            </Form>
        }
        {
            (isUpdate && !currentPost) &&
            <h3>נא לבחור הודעה לעריכה</h3>
        }
        <Modal isOpen= {isModalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={toggleModal}
        style={modalStyles}
        >
        <DivContainer>
        <div dir='rtl' style={{display: 'flex', 
                                flexDirection: 'row',
                                alignItems: 'center', 
                                justifyContent: 'space-between',
                                marginBottom: '20px',
                                }} >
            <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '50px'}}/>
            <div style= {{width: '10px'}}></div>
            <h4 dir='rtl'>האם ברצונך למחוק את ההודעה ?</h4>
            </div>
            <ModalDiv1>
            <CustomButton onClick={toggleModal} inverted>ביטול<FontAwesomeIcon icon={faWindowClose} /></CustomButton>
            <CustomButton onClick={handleDelete} deleteButton>מחיקה<FontAwesomeIcon icon={faUserSlash}/></CustomButton>
            </ModalDiv1>
        </DivContainer>
        </Modal>
        </PostFormContainer>
    )
};

const mapStateToProps = createStructuredSelector({
    currentPost: selectCurrentPost,
    error: selectPostsError
});

const mapDispatchToProps = dispatch => ({
    updatePost: (postId, updates)=> dispatch(startUpdatePost(postId, updates)),
    deletePost: (postId)=> dispatch(startDeletePost(postId)),
    addNewPost: (post)=> dispatch(startAddPost(post)),
    clearPostsError: ()=> dispatch(clearPostsError())
});

export default connect(mapStateToProps, mapDispatchToProps)(PostForm);