import postsActionTypes from './posts.types';

const INITIAL_STATE = {
    posts: [],
    isFetching: false,
    error: null,
    currentPost: null
};

const postsReducer = (state = INITIAL_STATE, action)=> {
    switch(action.type) {
        case postsActionTypes.GET_POSTS_START:
            return {
                ...state,
                isFetching: true
            }
        case postsActionTypes.GET_POSTS_SUCCESS:
            return {
                ...state,
                posts: [...action.payload.posts],
                isFetching: false,
                error: null
            }
        case postsActionTypes.GET_POSTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case postsActionTypes.SET_CURRENT_POST:
            return {
                ...state,
                currentPost: { ...action.payload }
            }
        case postsActionTypes.UPDATE_POST_START:
            return {
                ...state,
                isFetching: true
            }
        case postsActionTypes.UPDATE_POST_SUCCESS:
            let updatedPosts = [...state.posts.map(post => post.id ===action.payload.id ? action.payload : post)];
            return {
                ...state,
                posts: [...updatedPosts],
                isFetching: false,
                error: null,
                currentPost: null
            }            
        case postsActionTypes.UPDATE_POST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case postsActionTypes.DELETE_POST_START:
            return {
                ...state,
                isFetching: true
            }
        case postsActionTypes.DELETE_POST_SUCCESS:
            const newPostsArrayAfterDelete = [...state.posts.filter(post => post.id !== action.payload)]
            return {
                ...state,
                posts: [...newPostsArrayAfterDelete],
                currentPost: null,
                isFetching: false
            }
        case postsActionTypes.DELETE_POST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case postsActionTypes.CLEAR_POSTS_STATE:
            return {
                ...INITIAL_STATE
            }
        case postsActionTypes.ADD_POST_START:
            return {
                ...state,
                isFetching: true
            }
        case postsActionTypes.ADD_POST_SUCCESS:
            let updatedPostsArray = [...state.posts];
            updatedPostsArray.push(action.payload);
            return {
                ...state,
                posts: [...updatedPostsArray],
                isFetching: false
            }
        case postsActionTypes.ADD_POST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case postsActionTypes.CLEAR_POSTS_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    };
};

export default postsReducer;
