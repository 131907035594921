import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import LoginPage from '../components/login/login.component';
// import HomePage from '../pages/home/homepage.page';
import {default as HomePage} from '../pages/home/homepage-withspinner.container';
import Header from '../components/header/header.component';
import ChangePasswordPage from '../components/change-password/change-password.component';
import PostsPage from '../pages/posts/posts-page.page';
// import CreateNewUser from '../components/create-user/create-user.component';
// import UserDetails from '../components/user-details/user-details.container';

import {HelpPage, NotFoundPage} from '../pages/utility/utility.pages';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';



export const history = createBrowserHistory();

const AppRouter = () => (
    <Router history={history}>
    <div>
        <Header />
        <Switch>
            <PublicRoute path="/" component={LoginPage} exact={true}/>
            <PrivateRoute path="/dashboard" component={HomePage} exact={true}/>
            <PrivateRoute path="/profile/password" component={ChangePasswordPage} exact={true}/>
            <PrivateRoute path="/posts" component={PostsPage} exact={true} />
            <Route path="/help" component={HelpPage}/>
            <Route component={NotFoundPage}/>
        </Switch>
        </div>
    </Router>
);

export default AppRouter;

