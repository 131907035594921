import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUserSlash } from '@fortawesome/free-solid-svg-icons';

import { selectSiteUsersError, selectSiteUser } from '../../redux/site-users/site-users.selectors';
import { cleaError, startUpdateSiteUserPassword} from '../../redux/site-users/site-users.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import { UpdateUserContainer, 
         DivContainer, 
         FormContainer, 
         Spacer, 
         ButtonContainer ,
         HeaderDiv,
         ErrorDisplay,
         NotFoundDiv,
         ButtonsSeperator
        } from './change-user-password.styles';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

const ChangeUserPassword = (props) => {
    const { backendError, siteUser ,clearSiteUserError, startUpdateSiteUserPassword, currentUser } = props;
    const allowed = currentUser.UserID !== siteUser.UserID;
    const [ error, setError ] = useState(null);
    const [ passwords, setPasswords ] = useState({});
    const { newPassword = '', confirmNewPassword = '' }  = passwords;

    const handleChangePasswords = (event) => {
        const { name, value } = event.target;
        setPasswords({ ...passwords, [name]: value})
    };

    const handleSetError = (error) => {
        setError(error);
    };

    const handleClearError = () => {
        if(!!error) {
            setError(null);
        }
        if(!!backendError) {
            clearSiteUserError();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            email: siteUser.email,
            newPassword: passwords.confirmNewPassword
        }
        if(newPassword !== confirmNewPassword) {
            handleSetError('סיסמא לא מתאימה לאישור סיסמא');
            return;
        }
        startUpdateSiteUserPassword(payload);
        setPasswords({});
    };

    const handleCancel = () => {
        setPasswords({});
        handleSetError(null);
    }
    let siteUserExists = siteUser.UserID !== undefined;

    return (
        
        <DivContainer>
            {
                siteUserExists && 

            <UpdateUserContainer>
                <HeaderDiv>
                <div>
                    <h4><span>: פרטי משתמש</span></h4>
                </div>
                <div style={{width: '5px'}} />
                <div style={{color: 'lightsalmon'}}>
                    <h4><span>{siteUser.fullName}</span></h4>
                </div>
                <div style={{width: '30px'}} />
                <div>
                    {
                        siteUser.customerName !== '' && <h4><span>: שייך ללקוח</span></h4>
                    }
                    
                </div>
                <div style={{width: '5px'}} />
                <div style={{color: 'lightsalmon'}}>
                    <h4><span>{siteUser.customerName}</span></h4>
                </div>
                </HeaderDiv>
                {
                    allowed && (

                        <FormContainer>
                            <form onSubmit={handleSubmit}>
                                <ButtonsSeperator>
                                <div style={{width: '100%'}}>
                                 <FormInput 
                                    label='סיסמא חדשה'
                                    type='password' 
                                    name='newPassword' 
                                    value={newPassword || ''}
                                    onChange={handleChangePasswords} 
                                    onFocus={handleClearError}
                                    pattern=".{8,}"
                                    title="מינימום 8 תוים"
                                    required
                                    />
                                     <Spacer />
                                    <FormInput 
                                    label='אישור סיסמא חדשה'
                                    type='password' 
                                    name='confirmNewPassword' 
                                    value={confirmNewPassword || ''}
                                    onChange={handleChangePasswords} 
                                    onFocus={handleClearError}
                                    pattern=".{8,}"
                                    title="מינימום 8 תוים"
                                    required
                                    />
                                    </div>
                                    {
                                        error && <ErrorDisplay>
                                        <span>{error}</span>
                                        </ErrorDisplay>
                                    }
                                    {
                                        backendError && <ErrorDisplay>
                                        <span>{backendError}</span>
                                        </ErrorDisplay>
                                    }    
                                    <ButtonContainer>
                                        <CustomButton type='submit' inverted >שמירה<FontAwesomeIcon icon={faSave}/></CustomButton>
                                       
                                        <CustomButton type='button' onClick={handleCancel} inverted>ביטול<FontAwesomeIcon icon={faUserSlash} /></CustomButton>
                                    </ButtonContainer>
                                    </ButtonsSeperator>
                                    <Spacer />
                            </form>
                        </FormContainer>
                    )
                }
                {
                    !allowed && (
                        <NotFoundDiv>
                            <h3>לא ניתן לעדכן סיסמא למשתמש שלך דרך טופס זה<br />
                            לשינוי הסיסמא שלך יש להשתמש בקיצור הדרך בתפריט העליון </h3>
                        </NotFoundDiv>
                    )
                }
                </UpdateUserContainer>
        }
        {
            !siteUserExists && 
            <NotFoundDiv>
                <h3>לא נמצא משתמש להצגה</h3>
            </NotFoundDiv>

        }
        
        </DivContainer>
    )
};

const mapStateToProps = createStructuredSelector({
    backendError: selectSiteUsersError,
    currentUser: selectCurrentUser,
    siteUser: selectSiteUser
});

const mapDispatchToProps = dispatch => ({
    clearSiteUserError: () => dispatch(cleaError()),
    startUpdateSiteUserPassword: (data) => dispatch(startUpdateSiteUserPassword(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserPassword);