import userActionTypes from './user.types';
import { sendPostRequest } from '../../api/api';

export const signInStart = ( userCredentials ) => {
    return async dispatch => {
        try {
          const { email, password } = userCredentials;
          if(!email || !password) {
            throw new Error('Error signing in... check your credentials');
          }
          dispatch({ type: userActionTypes.SIGN_IN_START});  
          const userData = await sendPostRequest('/admin/signin',{...userCredentials});
          if(!userData){
            throw new Error('Error signing in... check your credentials');
          }
          sessionStorage.setItem('logged', true);
          dispatch({ type: userActionTypes.SIGN_IN_SUCCESS, payload: userData })
          return 'done';
        }catch(error){
          dispatch({ type: userActionTypes.SIGN_IN_FAILURE, payload: error.message})
    };
    
};
};

export const signoutStart = (userId)=> {
  return async (dispatch, getstate) => {
    try {

      if(!userId){
        throw new Error('Error signing out!');
      }
      const auth = getstate().user.currentUser.auth;
      dispatch({type: userActionTypes.SIGN_OUT_START})
      await sendPostRequest('/signout',{ userId: userId },{ authorization: auth})

      dispatch({ type: userActionTypes.SIGN_OUT_SUCCESS})
      return 'done';
    }catch(error){
      dispatch({ type: userActionTypes.SIGN_OUT_FAILURE, payload: error.message})
    }
  }
};

export const changePasswordStart = (oldPassword, email, password, history) => {

    return async (dispatch, getstate) =>{
      try {
        if(!oldPassword || !email || !password)
        {
          throw new Error('Error updating password');
        }
        const auth = getstate().user.currentUser.auth;
        dispatch({ type: userActionTypes.UPDATE_PASSWORD_START})
        const userData = await sendPostRequest('/profile/password/change', {oldPassword, email, password},{authorization: auth});
        if(!userData){
          throw new Error('Error Updating Password');
        }
        dispatch({type: userActionTypes.UPDATE_PASSWORD_SUCCESS, payload: userData});
        history.push('/dashboard');
        return 'done';
      }catch(error){
          dispatch({type: userActionTypes.UPDATE_PASSWORD_FAILURE, payload: error.message});
      }
    };
};

export const setUserError = (errorMessage) => ({
  type: userActionTypes.SET_USER_ERROR,
  payload: errorMessage
});

export const clearUserError = () => ({
  type: userActionTypes.CLEAR_USER_ERROR
});