import siteUsersActionTypes from './site-users.types';


const INITIAL_STATE = {
    siteUsers: [],
    error: null,
    isFetching: false,
    siteUser: {},
    updated: false,
    initialLoad: false
}

const siteUsersReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case siteUsersActionTypes.GET_SITE_USERS_START:
            return {
                ...state,
                error: null,
                isFetching: true

            }
        case siteUsersActionTypes.GET_SITE_USERS_SUCCESS:
            return {
                ...state,
                siteUsers: action.payload,
                error: null,
                isFetching: false,
            }
        case siteUsersActionTypes.GET_SITE_USERS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }
        case siteUsersActionTypes.CREATE_USER_START:
            return {
                ...state,
                error: null,
                isFetching: false
            }
        case siteUsersActionTypes.CREATE_USER_SUCCESS:
            let updatedSiteUsersArray = [...state.siteUsers];
            updatedSiteUsersArray.push(action.payload)    ;
        return {
                ...state,
                siteUsers: [...updatedSiteUsersArray],
                error: null,
                isFetching: false
            }
        case siteUsersActionTypes.CREATE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }       
        case siteUsersActionTypes.SELECT_SITE_USER:
            return{
                ...state,
                siteUser: action.payload
            }
        case siteUsersActionTypes.CLEAR_SITE_USER:
            return {
                ...state,
                siteUser: {}
            }
        case siteUsersActionTypes.CLEAR_SITE_USERS_STATE:
            return {
                ...state,
                ...INITIAL_STATE,
                isFetching: true
            }
        case siteUsersActionTypes.UPDATE_SITE_USER_START:
            return {
                ...state,
                error: null,
                isFetching: true,
                updated: false


            }         
        case siteUsersActionTypes.UPDATE_SITE_USER_SUCCESS:
            const updatedUsers = [...state.siteUsers.map(user => user.UserID === action.payload.UserID ? action.payload : user)]
            return{
                ...state,
                siteUsers: [...updatedUsers],
                siteUser: action.payload,
                error:null,
                isFetching:false,
                updated: true
            }
        case siteUsersActionTypes.UPDATE_SITE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
                updated: false
            }
        case siteUsersActionTypes.CLEAR_UPDATED:
            return {
                ...state,
                updated: false
            }
        case siteUsersActionTypes.SET_INITIAL_LOAD:
            return {
                ...state,
                initialLoad: true
            }
        case siteUsersActionTypes.CLEAR_INITIAL_LOAD:
            return {
                ...state,
                initialLoad: false
            }
        case siteUsersActionTypes.CLEAR_SITE_USERS_ERROR:
            return {
                ...state,
                error: null
            }
        case siteUsersActionTypes.DELETE_SITE_USER_START:
            return {
                ...state,
                isFetching: true
            }
        case siteUsersActionTypes.DELETE_SITE_USER_SUCCESS:
            const updatedSiteUsers = [...state.siteUsers.filter(user => user.UserID !== action.payload.deleteUserId)];
            return {
                ...state,
                siteUsers: [...updatedSiteUsers],
                siteUser: {},
                isFetching: false,
                error: null
            }
        case siteUsersActionTypes.DELETE_SITE_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case siteUsersActionTypes.CHANGE_SITE_USER_PASSWORD_START:
            return {
                ...state,
                isFetching: true
            }
        case siteUsersActionTypes.CHANGE_SITE_USER_PASSWORD_SUCCESS:
            const updatedUsersArray = [...state.siteUsers.map(user => user.UserID === action.payload.UserID ? action.payload : user)]
            return {
                ...state,
                siteUsers: [...updatedUsersArray],
                siteUser: action.payload,
                isFetching: false,
                error: null,
            }
        case siteUsersActionTypes.CHANGE_SITE_USER_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload
            }    
        default:
            return state;
    }

}

export default siteUsersReducer;