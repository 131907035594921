import styled from 'styled-components';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

export const Image = styled.img`
    height: 60px;
    margin-right: 20px; 
    align-self: flex-end;
    /* border:1px solid black; */
    flex-grow:1;
    @media screen and (max-width: 1024px) {
        height: 40px
    }
`
export const BigText = styled.div`
    color: #011a59;
    font-size:50px;
    text-decoration: none;
    /* margin-left: 20px; */
    /* border: 1px solid black; */
`

export const UserIconDiv = styled.div`
        display: flex;
        height:60px;
        width: 120px;
        background-color: white;
        flex-Direction: column;
        justify-content: space-around;
        align-items: center;
        /* margin-Right: 20px; */
        padding: 0;
        border: 1px solid black; 
        border-radius: 3px;
        @media screen and (max-width: 1024px){
            height: 40px;
            width: 100px;
        }
`
export const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
    font-size: 30px;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
`;

export const FontAwesomeIconStyled2 = styled(FontAwesomeIcon)`
    font-size: 30px;
    transform: rotate(90deg);
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
`;

export const Rotate = styled.div`
    animation: rotation 0.2s  ;
    animation-fill-mode: forwards;
    @keyframes rotation {
       100% {transform: rotate(90deg)}
    }

`
export const UnRotate = styled.div`
    animation: rotation 0.2s  ;
    animation-fill-mode: forwards;
    @keyframes rotation {
       100% {transform: rotate(90deg)}
    }

`
export const OptionsDiv = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-grow: 2;
    margin-right: 20px;
    margin-left: 20px;
`

export const HeaderContainer = styled.div`
    background-color: #F3F3F3;
    border-bottom: 1px solid black;
    /* box-shadow: 0px 10px 10px grey ; */
    height: 80px;
    width: 100vw;
    /* border: 1px solid orangered;  */
    position: sticky;
    top: 0;
    /* padding: 5px; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    ${BigText}:hover {
        font-weight: bolder;
        transform: scale(1.1);
        cursor: pointer;
    }
    ${UserIconDiv}:hover{
        cursor: pointer;
        color: #3FB449;
        background-color: black;
    }
    @media screen and (max-width: 1024px) {
        height: 50px;
        box-shadow: none;
    }
`

