import styled from 'styled-components';

export const UpdateUserContainer = styled.div`
    background-color: #F3F3F3;
    text-align: center;
    /* margin-top:30px; */
    padding-bottom:5px;
    width: 50vw;
    /* height: 59.5vh; */
    height: 100%;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    /* box-shadow: 10px 10px 10px grey; */
   @media screen and (max-width: 1024px) {
       width: 90vw;
   }
`;

export const NotFoundDiv = styled.div`
    background-color: #F3F3F3;
    text-align: center;
    /* margin-top:30px; */
    /* padding:20px; */
    width: 50vw;
    height: 60vh;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* box-shadow: 10px 10px 10px grey; */
    @media screen and (max-width: 1024px) {
        width:90vw ;
    }
    
`;

export const HeaderDiv = styled.div`
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    /* border:1px solid blue; */
    background-color: #222;
    color: #3FB449;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid black;
`;

export const DivContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    /* height: 64.2vh; */
    /* border: 1px solid green; */
    /* position: relative; */
    /* justify-content: center; */
    `;

export const ButtonContainer = styled.div`
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* justify-content: center; */
    `;

export const HGroup = styled.div`
    width: 50%;
    /* border: 1px solid red; */
    padding: 0px;
    margin: 0px;
    position: relative;
`;
export const HHGroup = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
`;

export const FormContainer = styled.div`
    width: 90%;
    /* border: 1px solid black; */
`;

export const ErrorDisplay = styled.div`
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: red;
    margin-top: 25px;
`;   

export const Spacer = styled.div`
    margin: 10px;
`;

export const Label = styled.label`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    background-color: white;
    font-size: 18px;
    height: 32px;
    padding-left: 10px;
    margin: 25px 0;
    color: ${props => props.isActive ? 'green' : 'red'};
    cursor: pointer;
`;

export const ModalDiv1 = styled.div`
    display: flex;
    flex-Direction: row-reverse;
    align-Items: center;
    justify-Content: space-between;
    width: 50%;
    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;