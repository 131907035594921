import styled from 'styled-components';

export const UserUsageLogContainer = styled.div`
    background-color: #F3F3F3;
    /* background-color: red; */
    text-align: center;
    /* margin-top:30px; */
    /* padding:20px; */
    width:50vw;
    /* height: 60.4vh; */
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* box-shadow: 10px 10px 10px grey; */
    position: relative;
    table-layout: fixed;
    @media screen and (max-width: 1024px){
        width: 90vw;
    }
    
`;

export const HeaderDiv = styled.div`
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    /* border:1px solid blue; */
    background-color: #222;
    color: #3FB449;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid black;
`;

export const LoggedInItemsHeader = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid black;
    width: 100%;
    height: 35px;
    background-color: #3FB449;
    position: absolute;
    top: 0;
    left: 0;
`;
export const UsageHeaderItem = styled.div`
    /* border: 1px solid blue; */
    width: 33.3%;
    font-weight: bold;
`;


export const LoggedInItem = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid black;
    border-top: none ;
    width: 100%;
    height: 50px;
    font-size: 14px;
`;
export const LoggedInItemDetail = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    width: 33%;
    border-left: 1px solid black;
    /* border-inline: 1px solid green; */
`;

export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden ;
    /* border: 1px solid red; */
    /* position: relative; */
    /* justify-content: center; */
    ${LoggedInItem}:hover {
        background-color: lightgreen;
        cursor: pointer;
        font-weight: 620;
    }
    `;

export const NotFoundDiv = styled.div`
background-color: #F3F3F3;
text-align: center;
/* margin-top:30px; */
/* padding:20px; */
width: 50vw;
height: 60vh;
border: 1px solid black;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
/* box-shadow: 10px 10px 10px grey; */
@media screen and (max-width: 1024px) {
    width: 90vw;
}

`;

export const Spacer = styled.div`
    width: 1px;
    height: 100%;
    border-right: 1px solid black;
`;

