import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {  createStructuredSelector } from 'reselect';
import { HeaderContainer , 
        Image, 
        OptionsDiv, 
        UserIconDiv, 
        Rotate, 
        UnRotate,
        FontAwesomeIconStyled,
        FontAwesomeIconStyled2
         } from './header.styles';
import DropdownMenu from '../dropdown-menu/dropdown-menu.component';
import logo from './pricoLogo.png.webp';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signoutStart } from '../../redux/user/user.actions';
import { startGetSiteUsers, clearSiteUsersState } from '../../redux/site-users/site-users.actions';
import { clearPostsState } from '../../redux/posts/posts-actions';

import { faBars} from '@fortawesome/free-solid-svg-icons';

const Header = ({ currentUser, signoutStart, startGetSiteUsers, clearSiteUsersState, clearPostsState }) => {
    const [ showMenu, setShowMenu ] = useState(false)
    const { ChangePassword } = currentUser || false;
    
    const history = useHistory();

    const handleShowMenu = () => setShowMenu(!showMenu);

    const handleSignout = ()=> {
        clearSiteUsersState();
        clearPostsState();
        signoutStart(currentUser.UserID);
    }

    useEffect(()=> {
        if(currentUser) {
            if(ChangePassword) {
                history.push('/profile/password')
            }
            startGetSiteUsers()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser, ChangePassword]);

    useEffect(()=>{
        if(showMenu) {
            window.addEventListener('click', handleShowMenu)
        } 
        return ( )=> {
            if(showMenu) {
                window.removeEventListener('click', handleShowMenu)
            }
        }
    },[showMenu,handleShowMenu])

    const Options = () => (
        <div>
        <OptionsDiv>
        {showMenu ? <DropdownMenu handleSignout={ handleSignout } setShowMenu={setShowMenu} changePass={currentUser.ChangePassword}/> : null }
        <div></div>
        <UserIconDiv onClick={handleShowMenu}>
        {
            showMenu 
            ? <Rotate><FontAwesomeIconStyled icon={faBars} /></Rotate>
            : <UnRotate><FontAwesomeIconStyled2 icon={faBars} /></UnRotate>
        }
        
        <h6 style={{margin: '0'}}>שלום {currentUser.FirstName}</h6>
        </UserIconDiv>
        </OptionsDiv>
        </div>

    );
        
    return (
        <HeaderContainer>
        <Link to='/'><Image src={logo} /></Link>
        {
            currentUser && (
                <div>
                <Options /> 
                </div>
                )
            
        }
        </HeaderContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
    signoutStart: (userId, auth) => dispatch(signoutStart(userId, auth)),
    startGetSiteUsers: () => dispatch(startGetSiteUsers()),
    clearSiteUsersState: () => dispatch(clearSiteUsersState()),
    clearPostsState:()=> dispatch(clearPostsState())
})

export default connect(mapStateToProps,mapDispatchToProps)(Header);