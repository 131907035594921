import styled from 'styled-components';

export const PostFormContainer = styled.div`
    /* direction: rtl; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    /* border: 1px solid lightcoral; */
    `;

export const Form = styled.form`
    width: 97%;
    height: 97%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
`;

export const Label = styled.label`
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    background-color: white;
    font-size: 18px;
    height: 32px;
    padding-left: 10px;
    margin: 25px 0;
    color: ${props => props.isActive ? 'green' : 'red'};
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    direction: rtl;
    /* height: 100vh; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    width: inherit;
    top: 90%;
    /* left: 0; */
    /* justify-content: center; */
    `;

export const ModalDiv1 = styled.div`
display: flex;
flex-Direction: row-reverse;
align-Items: center;
justify-Content: space-between;
width: 50%;
@media screen and (max-width: 800px){
    flex-direction: column;
}
`;

export const DivContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    /* height: 64.2vh; */
    /* border: 1px solid green; */
    /* position: relative; */
    /* justify-content: center; */
    `;
