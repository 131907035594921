import { createSelector } from 'reselect';

const selectSiteUsersParent = state => state.siteUsers;

export const selectSiteUsers = createSelector(
    [selectSiteUsersParent],
    siteUsers => siteUsers.siteUsers
);

export const selectSiteUsersError = createSelector(
    [selectSiteUsersParent],
    siteUsers => siteUsers.error
);

export const selectSiteUsersIsFetching = createSelector(
    [selectSiteUsersParent],
    siteUsers => siteUsers.isFetching
);

export const selectSiteUser = createSelector(
    [selectSiteUsersParent],
    siteUsers => siteUsers.siteUser
);

export const selectUpdated = createSelector(
    [selectSiteUsersParent],
    siteUsers => siteUsers.updated
);

export const selectIsInitialLoad = createSelector(
    [selectSiteUsersParent],
    siteUsers => siteUsers.initialLoad
);

