import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession  from 'redux-persist/lib/storage/session';

import userReducer from './user/user.reducer';
import siteUsersReducer from './site-users/site-users.reducer';
import postsReducer from './posts/posts.reducer';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist:['user']
};

const rootReducer = combineReducers({
    user:  userReducer,
    siteUsers: siteUsersReducer,
    posts: postsReducer
});

export default persistReducer(persistConfig,rootReducer);

