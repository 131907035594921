import React,{ useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import { ReactTabulator } from 'react-tabulator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
// import { saveAs } from 'file-saver';
// import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator_site.min.css';
import './homepage.styles.css';
import { selectChangePassword } from '../../redux/user/user.selectors';
import { selectSiteUsers, 
         selectSiteUsersError, 
         selectSiteUser, 
         selectUpdated,
          } from '../../redux/site-users/site-users.selectors';
import { setSelectedSiteUser, clearUpdated } from '../../redux/site-users/site-users.actions';
import exportUsersToExcel from '../../util/excel-users-report';


const HomePage = (props) => {
    const tableRef = useRef(null);

    const { changePassword, 
            siteUsers, 
            setSelectedSiteUser, 
            siteUser,
            updated,
            clearUpdated,
        } = props;
    // let data = siteUsers.map(user => {
    //     user.fullName = user.firstName +' ' +user.lastName;
    //     user.type = (user.isAdmin &&'עובד פריקו') || (!user.isAdmin && 'לקוח');
    //     user.createdAt = new Date(user.createDate).toLocaleDateString('en-gb',{timeZone: 'UTC'});
    //     user.lastLoginD = user.lastLoginTime ? new Date(user.lastLoginTime).toLocaleDateString('en-gb', {timeZone: 'UTC'}) : null;
    //     user.lastLoginT = user.lastLoginTime ? new Date(user.lastLoginTime).toLocaleTimeString('en-gb',{timeZone: 'UTC'}) : null;
    //     return user;
            
    //     });
        const handleExport = () => {
        
        const headers = [
            {value: 'קוד', key: 'A'},
            {value: 'שם', key: 'B'},
            {value: 'כתובת מייל', key: 'C'},
            {value: 'נייד', key: 'D'},
            {value: 'מספר לקוח', key: 'E'},
            {value: 'שם הלקוח', key: 'F'},
            {value: 'סוג משתמש', key: 'G'},
            {value: 'פעיל', key: 'H'},
            {value: 'הוקם בתאריך', key: 'I'},
            {value: 'הוקם ע"י', key: 'J'},
            {value: 'כניסה אחרונה', key: 'K'},
            {value: 'שעה', key: 'L'},
        ];
                const data = tableRef.current.table.getData('active');
                const fixedData = data.map(obj => {
                    delete obj.changePassword
                    delete obj.createDate
                    delete obj.isOnline
                    delete obj.isAdmin
                    delete obj.firstName
                    delete obj.lastName
                    obj.isActive = obj.isActive ? 'כן' : null

                    return obj
                })
                let parsedData = []
                fixedData.map(obj => {
                    let row = []
                    row.push(obj.UserID,
                        obj.fullName,
                        obj.email,
                        obj.cellPhone,
                        obj.customerNumber,
                        obj.customerName,
                        obj.type,
                        obj.isActive,
                        obj.createdAt,
                        obj.createdBy,
                        obj.lastLoginD,
                        obj.lastLoginT
                        )
                        parsedData.push(row)

                    return obj
                })
                const filters = tableRef.current.table.getFilters(true);
                exportUsersToExcel(parsedData,headers,filters);
           // });

    };
    
    
    let data = siteUsers.map(user => {
        user.fullName = user.firstName + ' ' +user.lastName;
        user.type = (user.isAdmin &&'עובד פריקו') || (!user.isAdmin && 'לקוח');
        user.createdAt = new Date(user.createDate).toLocaleDateString('en-gb',{timeZone: 'UTC'});
        user.lastLoginD = user.lastLoginTime ? new Date(user.lastLoginTime).toLocaleDateString('en-gb', {timeZone: 'UTC'}) : null;
        user.lastLoginT = user.lastLoginTime ? new Date(user.lastLoginTime).toLocaleTimeString('en-gb',{timeZone: 'UTC'}) : null;
        return user;
        });

    const handleRowClick = (e, row) => {
        // console.log(row.getData())
        //     const selectedUserIndex = siteUsers.findIndex(obj => obj.UserID === row.getData().UserID);
        //     console.log('index:'+ selectedUserIndex)
        //     const userSelected = {...siteUsers[selectedUserIndex]};
            setSelectedSiteUser({...row.getData()});
    }

    const columns = [
        {title: 'קוד', field: 'UserID', width: 65, hozAlign: 'center', responsive: 0, minWidth: 65},
        {title: 'שם', field: 'fullName', hozAlign: 'right', width: 90, headerFilter: 'input',
        headerFilterPlaceholder: 'חיפוש בשם', responsive: 0 },
        {title: 'כתובת מייל', field: 'email', width: 120, headerFilter: 'input', headerFilterPlaceholder: 'חיפוש לפי מייל'},
        {title: 'נייד', field: 'cellPhone', width: 100, hozAlign: 'center', headerFilter: 'input',
        headerFilterPlaceholder:'חיפוש לפי טלפון'},
        {title: 'לקוח', field: 'customerName', hozAlign: 'center', width: 80, headerFilter: 'input',
        headerFilterPlaceholder: 'חיפוש לפי לקוח'},
        {title: 'סוג', field: 'type', hozAlign: 'center', width: 65 , headerFilter: 'select', 
        headerFilterPlaceholder: 'חיפוש לפי סוג',headerFilterParams:{values:true}},
        // {title: 'הוקם בתאריך', field: 'createdAt',width: 130, hozAlign: 'center'},
        // {title: 'הוקם ע"י', field: 'createdBy',width: 100, hozAlign: 'right'},
        {title: 'פעיל', field: 'isActive', width: 73, hozAlign: 'center', formatter:"tickCross", formatterParams:{
            allowEmpty:true,
            allowTruthy:true,
          
        }, headerFilter: 'tickCross',headerFilterParams:{"tristate":true},headerFilterEmptyCheck:function(value){return value === null}},
        {
            title: 'מחובר', 
            field: 'isOnline', 
            width: 83, 
            formatter: 'traffic', 
            formatterParams: {min:0, max:1,color:['transparent','#3FB449']},
            hozAlign: 'center',
            download: false,
            headerFilter: 'tickCross',
            headerFilterParams:{"tristate":true},
            headerFilterEmptyCheck:function(value){return value === null}
        }
    ];

    const tableOptions = {
        dataFiltered: function(filters, rows){
            const f = document.getElementById('filtered1')
            f.innerText = rows.length;
         },
        renderComplete: function() {
            this.setLocale('he-il');
            if(updated) {
                const rows = this.getRows()
                const row = rows[rows.findIndex(r=> r.getData().UserID === siteUser.UserID)]
                this.selectRow(row);
                clearUpdated();
            }
         },

        selectable: 1,
        selectablePersistence: true,
        textDirection: 'rtl',
        resizableColumns:false,
        pagination: 'local',
        paginationSize: 9,
        paginationButtonCount: 3,
        responsiveLayout: "hide",
        langs: {
            "he-il": {
                "pagination" : {
                    "first": "ראשון",
                    "last": "אחרון",
                    "next": "הבא",
                    "prev": "הקודם"
                }
            }
        }
    };

    
    return (
        changePassword 
        ?( 
            <Redirect to='/profile/password'/>
         )
        :(  
                <div className="tabulator-container">
                <div className='table-stats'>
                <h4>מציג <span id='filtered1'></span> מתוך <span id='total1'>{siteUsers.length}</span>
                <span> משתמשים </span></h4>
                </div>
                <div className='table-actions'>
                <div className='table-action' onClick={handleExport}>הורדה לאקסל<span style={{marginLeft:'10px'}}><FontAwesomeIcon icon={faFileDownload} /></span></div>
            </div>
                <ReactTabulator
                    id='table1'
                    ref={tableRef}
                    data={data}
                    height='40vh'
                    columns={columns}
                    layout='fitColumns'
                    options={tableOptions}
                    rowClick={handleRowClick}
                    // rowSelected={handleRowClick}
                    tooltips={true}
                    initialSort={[{column: 'UserID', dir: 'desc'}]}
                    tooltipsHeader={true}
                />
                </div>
          
        )
    )
}

const mapStateToProps = createStructuredSelector({
    changePassword: selectChangePassword,
    siteUsers: selectSiteUsers,
    error: selectSiteUsersError,
    siteUser: selectSiteUser,
    updated: selectUpdated
});

const mapDispatchToProps = dispatch => ({
    setSelectedSiteUser: user => dispatch(setSelectedSiteUser(user)),
    clearUpdated: ()=> dispatch(clearUpdated())

});
export default connect(mapStateToProps,mapDispatchToProps)(HomePage);