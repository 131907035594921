import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';

import { DivContainer, ChangePasswordPageContainer, FormContainer, ErrorDisplay, ButtonContainer, PageHeader } from './change-password.styles';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import { selectUserError, selectUserEmail } from '../../redux/user/user.selectors';
import { clearUserError, setUserError, changePasswordStart } from '../../redux/user/user.actions';

const ChangePasswordPage =({ error, email, clearUserError, setUserError, changePasswordStart, history })=> {
    const  [userCredentials, setCredentials ] = useState({ oldPass: '', newPass: '', confirmPass: ''});
    const { oldPass, newPass, confirmPass } = userCredentials;

    const passwordsMatch = newPass === confirmPass;
    
    
    const handleSetError = () => {
        let errorMessage;
        if(!passwordsMatch){
            errorMessage = 'הסיסמא החדשה אינה תואמת את אישור הסיסמא';
            setUserError(errorMessage);
        }
    };
    
    const handleClearError = () => {
        if(error){
            clearUserError();
        }
    };

    const handleChange = event => {
        const { value, name } = event.target;
        setCredentials({...userCredentials,[name]: value});
    };

    const handleSubmit = (event)=> {
        event.preventDefault();
        changePasswordStart(oldPass, email, newPass, history);
    };

    const handleCancel = () => {
        history.push('/dashboard')
    }

    return (
    <DivContainer>
    <PageHeader>
    <h2>החלפת סיסמא</h2>
    </PageHeader>
        <ChangePasswordPageContainer>
            <FormContainer>
                <form onSubmit={handleSubmit}>
                <FormInput 
                name='oldPass' 
                type='password' 
                value={oldPass} 
                handleChange={handleChange}
                label='סיסמא ישנה'
                required
                onFocus={handleClearError}
                />
                <FormInput 
                name='newPass' 
                type='password' 
                value={newPass} 
                handleChange={handleChange}
                label='סיסמא חדשה'
                required
                onFocus={handleClearError}
                pattern=".{8,}"
                title="מינימום 8 תוים"
                />
                <FormInput 
                name='confirmPass' 
                type='password' 
                value={confirmPass} 
                handleChange={handleChange}
                label='אישור סיסמא'
                required
                onFocus={handleClearError}
                onBlur={handleSetError}
                pattern=".{8,}"
                title="מינימום 8 תוים"
                />
                {
                    error && <ErrorDisplay>
                        <span>{error}</span>
                    </ErrorDisplay>
                }
                <ButtonContainer className='button-container'>
                <CustomButton type='submit' inverted>שמירה<FontAwesomeIcon icon={faSave} /></CustomButton>
                <CustomButton type='button' onClick={handleCancel} inverted>ביטול<FontAwesomeIcon icon={faWindowClose} /></CustomButton>
                </ButtonContainer>
                </form>
            </FormContainer>
        </ChangePasswordPageContainer>
    </DivContainer>
)
};

const mapStateToProps = createStructuredSelector({
    error: selectUserError,
    email: selectUserEmail
})

const mapDispatchToProps = dispatch => ({
    setUserError: (errorMessage) => dispatch(setUserError(errorMessage)),
    clearUserError: ()=> dispatch(clearUserError()),
    changePasswordStart: (oldPass, email, newPass, history)=> dispatch(changePasswordStart(oldPass, email, newPass, history))
})

export default connect(mapStateToProps,mapDispatchToProps)(ChangePasswordPage);