import styled from 'styled-components';

export const DivContainer = styled.div`
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    `

export const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F3;
    color: black;
    width: 100vw;
    height: 25px;
    padding: 15px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid black ;
    box-shadow: 0px 10px 10px grey ;
`;
    
export const ErrorDisplay = styled.div`
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: red;
    margin-top: 25px;
`    
export const Spacer = styled.div`
    margin-bottom: 25px;
`;

export const LoginPageContainer = styled.div`
    background-color: #F3F3F3;
    text-align: center;
    margin-top: 20vh;
    width: 30vw;
    /* height: 50vh; */
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 10px grey;
    padding: 25px;
`

export const FormContainer = styled.div`
    width: 90%;
`