import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectSiteUsersIsFetching } from '../../redux/site-users/site-users.selectors';
import WithSpinner from '../../components/with-spinner/with-spinner.copmonent';
import HomePageContainer from './homepage.container';


const mapStateToProps = createStructuredSelector({
    isLoading: selectSiteUsersIsFetching
});

const HomePageWithSpinner = compose(
connect(mapStateToProps),
WithSpinner
)(HomePageContainer)

export default HomePageWithSpinner;
