import styled from 'styled-components';

export const Container = styled.div`
    direction: rtl;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
    height: 91.4vh;
    flex-grow:1 ;
    overflow-x: hidden;
    /* overflow-y: hidden; */
`;

export const PageHeader = styled.div`
    direction:rtl;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 25px;
    padding: 15px 0;
    border-bottom: 1px solid black;
`;

export const FilterHeader = styled.div`
    direction: rtl;
    background-color: lightcyan;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 30px;
    padding: 15px 0;
    border-bottom: 1px solid black;
`;

export const MainDiv = styled.div`
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* border: 1px solid green; */
    /* background-color: #f2f2f2; */
    height: 100%;
    /* width: 100%; */
    flex-grow: 2;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        flex-grow: 0;
        align-items: center;
        justify-content: space-between;
    }
`;

export const RightPane = styled.div`
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 10px;
    /* background-color: #f2f2f2; */
    width: 50%;
    flex-grow: 0;
    /* border-left: 1px solid black; */
    @media screen and (max-width: 800px) {
        border-left: none;
        width: 100%;
        height: 60%;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        margin-left: 0;
    }
`
export const PostsContainer = styled.div`
    direction: rtl;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 20px;
    /* height: 90%; */
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 10px 10px 10px grey;
    background-color: #f2f2f2;
    @media screen and (max-width: 800px) {
        width: 80%;
        height: 100%;
    }
`;

export const LeftPane = styled.div`
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    flex-grow: 0;
    margin-right: 10px;
    /* background-color: lightpink; */
    @media screen and (max-width: 800px) {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }
`;

export const FormDiv = styled.div`
    direction: rtl;
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 50vh;
    border: 1px solid black;
    /* border-radius: 5px; */
    border: 1px solid black;
    box-shadow: 10px 10px 10px grey;
    background-color: #f2f2f2;
    @media screen and (max-width: 800px) {
        width: 80%;
        height: 100%;
    }
`;

export const Header = styled.div`
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: white;
    background-color: black;
    height: 4vh;
    font-weight: bold;
`;