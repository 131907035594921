import styled from 'styled-components';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const CheckedIcon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
  stroke: #3FB449;
  stroke-width: 4px;
  stroke-linecap: round;
`
export const UncheckedIcon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
  stroke: red;
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-linejoin: round;
`
export const StyledCheckbox = styled.div`
    position:relative;
    display: inline-block;
    align-items: center;
    margin-top: 5px;
    width: 18px;
    height: 18px;
    background: white;
    border-radius: 3px;
    transition: all 200ms;
    ${HiddenCheckbox}:hover + &{
      box-shadow: 0 0 0 3px lightgray;
    }
    ${CheckedIcon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
  ${UncheckedIcon} {
    visibility: ${props => props.checked ? 'hidden' : 'visible'}
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

