import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReactTabulator } from 'react-tabulator';

import { selectPosts } from '../../redux/posts/posts.selectors';
import { selectPost } from '../../redux/posts/posts-actions';
import './posts-table.styles.css';

const PostsTable = (props) => {
    const { posts,  selectPost } = props;

    const postsTableRef = useRef(null);

    const columns = [
        {title: 'יוצר', field: 'createdBy', hozAlign: 'right', width: 110,headerFilter: 'input', headerFilterPlaceholder: 'חיפוש בשם'},
        {title: 'תאריך ושעה', field: 'd', hozAlign: 'right', width: 140,headerFilter: 'input', headerFilterPlaceholder: 'חיפוש בתאריך'},
        {title: 'כותרת', field: 'title', hozAlign: 'right', width: 140,headerFilter: 'input', headerFilterPlaceholder: 'חיפוש בכותרת'},
        {title: 'הודעה פעילה', field: 'isActive', hozAlign: 'right', width: 140, formatter:"tickCross",
        formatterParams:{
            allowEmpty:true,
            allowTruthy:true,
        }, headerFilter: 'tickCross',headerFilterParams:{"tristate":true},
        headerFilterEmptyCheck:function(value){return value === null}},

        
    ];

    let data = [];

    posts.forEach(post => {
        const obj = { ...post };
        data.push(obj);
    });

    data.map(post => {
        post.d = new Date(post.createdAt).toLocaleTimeString('en-gb', {timeZone: 'UTC'}).substr(0,5) + ' ' + 
        new Date(post.createdAt).toLocaleDateString('en-gb', {timeZone: 'UTC'});
        
        return post;
    });

    const handleRowClick = (e,row) => {
        selectPost({...row.getData()});
    };

    const postsTableOptions = {
        dataFiltered: function(filters, rows){
            const f = document.getElementById('filtered1')
            f.innerText = rows.length;
         },
        renderComplete: function() {
            this.setLocale('he-il');
         },
        //  initialFilter:[
        //     {field:"isActive", type:"=", value: true}
        // ],
        selectable: 1,
        selectablePersistence: true,
        textDirection: 'rtl',
        resizableColumns:false,
        pagination: 'local',
        paginationSize: 9,
        paginationButtonCount: 3,
        responsiveLayout: "hide",
        langs: {
            "he-il": {
                "pagination" : {
                    "first": "ראשון",
                    "last": "אחרון",
                    "next": "הבא",
                    "prev": "הקודם"
                }
            }
        }
    };
    
    return (
        <div>
            <div className='table-stats'>
                <h4>מציג <span id='filtered1'></span> מתוך <span id='total1'>{posts.length}</span>
                <span> הודעות </span></h4>
            </div>
            <ReactTabulator
            id='postsTable'
            ref={postsTableRef}
            data={data}
            height='41vh'
            columns={columns}
            layout='fitColumns'
            options={postsTableOptions}
            rowClick={handleRowClick}
            // rowSelected={handleRowClick}
            tooltips={true}
            initialSort={[{column: 'd', dir: 'desc'}]}
            tooltipsHeader={true}
        />
        </div>
    );    

};

const mapStateToProps = createStructuredSelector({
    posts: selectPosts
});

const mapDispatchToProps = dispatch => ({
    selectPost: (post)=> dispatch(selectPost(post))
});

export default connect(mapStateToProps,mapDispatchToProps)(PostsTable);
