import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { LoginPageContainer, DivContainer, FormContainer, ErrorDisplay, Spacer, PageHeader } from './login.styles';
import FormInput from "../form-input/form-input.component";
import CustomButton from '../custom-button/custom-button.component';
import { signInStart, clearUserError } from '../../redux/user/user.actions';
import { selectUserError } from '../../redux/user/user.selectors';

const LoginPage = ({ signInStart, error, clearUserError })=> {
    const  [userCredentials, setCredentials ] = useState({ email: '', password: ''});
    const { email, password } = userCredentials;
    const handleChange = event => {
        const { value, name } = event.target;
        setCredentials({...userCredentials,[name]: value});
    };

    const handleSubmit = event => {
        event.preventDefault();
        signInStart(userCredentials);
    };
    const handleClearError = () => {
        if(error){
            clearUserError();
        }
    }

    return(
        <DivContainer>
        <PageHeader>
            <h2>כניסה למערכת ניהול אתר לקוחות</h2>
        </PageHeader>
            <LoginPageContainer>
                   <FormContainer>
                        <form onSubmit={handleSubmit}>
                            <FormInput name='email' 
                            type='email' 
                            value={email} 
                            handleChange={handleChange}
                            label='כתובת מייל'
                            required
                            onFocus={handleClearError}/>
                            <FormInput name='password' 
                            type='password' 
                            value={password}
                            handleChange={handleChange}
                            label='סיסמא'
                            required
                            onFocus={handleClearError}/>
                            <Spacer />
                            <DivContainer className='button-container'>
                                <CustomButton type='submit' inverted >כניסה</CustomButton>
                            </DivContainer>
                            {
                                error && <ErrorDisplay>
                                    <span>...הייתה בעיה בכניסה</span>
                                    יש לבדוק את שם המשתמש והסיסמא
                                </ErrorDisplay>
                            }
                        </form>
                    </FormContainer>
            </LoginPageContainer>
        </DivContainer>
    )
};
const mapStateToProps = createStructuredSelector({
    error: selectUserError
})

const mapDispatchToProps = dispatch => ({
    signInStart: userCredentials => dispatch(signInStart(userCredentials)),
    clearUserError: ()=> dispatch(clearUserError())
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);