import { sendGetRequest, sendPostRequest, sendDeleteRequest } from '../../api/api';
import siteUsersActionTypes from './site-users.types';

export const startGetSiteUsers = ()=> {
    return async (dispatch, getState) => {
        try{
            dispatch({type: siteUsersActionTypes.GET_SITE_USERS_START });
            const siteUsersData = await sendGetRequest('/admin/users',{},{authorization: getState().user.currentUser.auth});
            if(!siteUsersData || siteUsersData.length === 0){
                throw new Error('כשלון בשליפת נתונים');
            }
            dispatch({type: siteUsersActionTypes.GET_SITE_USERS_SUCCESS, payload: siteUsersData });
            return 'done';
        }catch(error){
            dispatch({type: siteUsersActionTypes.GET_SITE_USERS_FAILURE, payload: error.message });
        }
    };
};

export const startCreateNewUser = (newUserDetails) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: siteUsersActionTypes.CREATE_USER_START});
            const auth = getState().user.currentUser.auth;
            const newUserData = await sendPostRequest('/register',{...newUserDetails},{authorization: auth});
            if(!newUserData) {
                throw new Error('כשלון בשמירת משתמש חדש!');
            }
            dispatch({type: siteUsersActionTypes.CREATE_USER_SUCCESS, payload: newUserData});
            return 'done';
        }catch(error){
            dispatch({ type: siteUsersActionTypes.CREATE_USER_FAILURE, payload: error.message});
        }
        
    };
};

export const startUpdateSiteUser = (updatedUserDetails, userId) => {
    return async (dispatch, getState) => {
        try {
        dispatch({type: siteUsersActionTypes.UPDATE_SITE_USER_START});
        const auth = getState().user.currentUser.auth;
        const updatedUserData = await sendPostRequest(`/profile/${userId}`,{...updatedUserDetails},{authorization: auth});
        if(!updatedUserData) {
            throw new Error('כשלון בעדכון פרטי משתמש !');
        }
        dispatch({type: siteUsersActionTypes.UPDATE_SITE_USER_SUCCESS, payload: updatedUserData});
        return 'done'
        } catch(error) {
            dispatch({type: siteUsersActionTypes.UPDATE_SITE_USER_FAILURE, payload: error.message});

        }
    };
};

export const startDeleteSiteUser = (userId, userEmail) => {
    const data = { deleteUserId: userId, deleteUserEmail: userEmail }
    return async (dispatch, getState) => {
        try {
            dispatch({type: siteUsersActionTypes.DELETE_SITE_USER_START});
            const auth = getState().user.currentUser.auth;
            const updatedUserData = await sendDeleteRequest('/admin/users/delete', {...data}, { authorization: auth});
            const {success} = updatedUserData
            if (!success) {
                throw new Error('כשלון במחיקת משתמש');
            };
            dispatch({type: siteUsersActionTypes.DELETE_SITE_USER_SUCCESS, payload: data});
            return 'done';
        } catch(error) {
            dispatch({type: siteUsersActionTypes.DELETE_SITE_USER_FAILURE, payload: error.message});
        };
    };
};

export const setSelectedSiteUser = (siteUser) => ({
    type: siteUsersActionTypes.SELECT_SITE_USER,
    payload: siteUser
});

export const clearSiteUser = () => ({
    type: siteUsersActionTypes.CLEAR_SITE_USER
});


export const clearSiteUsersState = () => ({
    type: siteUsersActionTypes.CLEAR_SITE_USERS_STATE
});

export const clearUpdated = () =>({
    type: siteUsersActionTypes.CLEAR_UPDATED
});

export const setInitialLoad = () => ({
    type: siteUsersActionTypes.SET_INITIAL_LOAD
});

export const clearInitialLoad = () => ({
    type: siteUsersActionTypes.CLEAR_INITIAL_LOAD
});

export const cleaError = () => ({
    type: siteUsersActionTypes.CLEAR_SITE_USERS_ERROR
});

export const startUpdateSiteUserPassword = (data) => {
    return async (dispatch, getState) => {
        try {
            const auth = getState().user.currentUser.auth;
            dispatch({type: siteUsersActionTypes.CHANGE_SITE_USER_PASSWORD_START});
            const updatedUser = await sendPostRequest('/admin/users/password', {...data}, {authorization: auth});
            if(!updatedUser.UserID) {
                throw new Error('הייתה בעיה בהחלפת הסיסמא');
            };
            dispatch({ type: siteUsersActionTypes.CHANGE_SITE_USER_PASSWORD_SUCCESS, payload: updatedUser});
            return 'done';
        }catch(error) {
            dispatch({ type: siteUsersActionTypes.CHANGE_SITE_USER_PASSWORD_FAILURE, payload: error.message});
        };

    };
};