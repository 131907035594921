const postsActionTypes = {
    GET_POSTS_START: 'GET_POSTS_START',
    GET_POSTS_SUCCESS: 'GET_POSTS_SUCCESS',
    GET_POSTS_FAILURE: 'GET_POSTS_FAILURE',
    SET_CURRENT_POST: 'SET_CURRENT_POST',
    UPDATE_POST_START: 'UPDATE_POST_START',
    UPDATE_POST_SUCCESS: 'UPDATE_POST_SUCCESS',
    UPDATE_POST_FAILURE: 'UPDATE_POST_FAILURE',
    DELETE_POST_START: 'DELETE_POST_START',
    DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
    DELETE_POST_FAILURE: 'DELETE_POST_FAILURE',
    CLEAR_POSTS_STATE: 'CLEAR_POSTS_STATE',
    ADD_POST_START: 'ADD_POST_START',
    ADD_POST_SUCCESS: 'ADD_POST_SUCCESS',
    ADD_POST_FAILURE: 'ADD_POST_FAILURE',
    CLEAR_POSTS_ERROR: 'CLEAR_POSTS_ERROR'
};

export default postsActionTypes;