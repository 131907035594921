import HomePage from './homepage.page';
import UserDetails from '../../components/user-details/user-details.container';

import './homepage.styles.css';
import { Spacer } from '../../components/user-details/user-details.styles';

const HomePageContainer = (props) => {
        
    return (
        <div className='main-container'>
        <div className='page-header'>
            <h2>רשימת משתמשים באתר פריקו</h2>
        </div>
        <div className='main-filter-form' >
        
            <div className='main-data-container' >
             <HomePage />
             <Spacer />
             <UserDetails />
             </div>
             </div>
        </div>
    )

}


export default HomePageContainer;