import styled from 'styled-components';
// import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';



export const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    color: ${props => props.pathname !== props.loc ? 'black' : '#3FB449' };
    @media screen and (max-width: 800px) {
        height: 30px;

    }
`
export const H4 = styled.h4`
    font-size: 1em;
    @media screen and (max-width: 800px) {
        font-size: 0.8em;
    }
`;

export const IconDiv = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 5px;
    width:40px;
    font-size: 20px;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`

export const Seperator = styled.div`
    width: 100%;
    border-bottom: 1px solid black;
`

export const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 160px;
    border: 1px solid black;
    border-radius: 3px;
    box-shadow: 0px 10px 10px grey ;
    position: absolute;
    background-color: white;
    /* margin-left: 20px; */
    top: 75px;
    left: 20px;
    z-index:100;
    ${MenuItem}:hover {
        background-color: black;
        color: #3FB449;
        cursor: pointer;
    }
    animation: fadeIn 0.5s;
    animation-fill-mode: forwards;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            width: 1;
        }
    }
    @media screen and (max-width: 800px) {
        height: 90px;
        width: 150px;
        top: 48px;
    }
`

