import { saveAs } from 'file-saver';

const exportUsersToExcel = async (data, headers, filters) => {
    const { XlsxPopulate } = window;
    const wb = await  XlsxPopulate.fromBlankAsync();
    var startDataLine = 1;
    var now = new Date(Date.now()).toLocaleDateString('en-gb')
    const ws = wb.addSheet(`פירוט משתמשי אתר אינטרנט`);
    wb.deleteSheet('Sheet1')
    ws.range('A1:A1').value(`פירוט משתמשי אתר אינטרנט לתאריך ${now}`);
    ws.range('A1:L1').merged(true);
    // ws.range('A2:L2').merged(false);
    ws.range('A1:L1').style('fill', {
        type: 'solid',
        color:  '000033'
    })
    ws.range('A1:L1').style('bold', true)
    ws.range('A1:L1').style({fontColor: 'ffff00'})
    startDataLine += 1;

    //Set Headers
    headers.map(obj => {
        let newKey;
        newKey= obj.key + startDataLine + ':' + obj.key + startDataLine
        ws.range(newKey).value(obj.value)
      return obj;
    });
    ws.range('A2:L2').style('fill', {
        type: 'solid',
        color:  '000033'
    })
    ws.range('A2:L2').style({fontColor: 'ffff00'})
    ws.range('A2:L2').style('bold', true)
    startDataLine += 1
    //Apply Styling
    ws.range('A1:L1').style({fontFamily: 'Arial'})
    ws.range('A1:L1').style({fontSize: '12'})
    ws.range('A1:L1').style({horizontalAlignment: 'center'})
    ws.column('B').style({horizontalAlignment: 'right'})
    ws.column('D').style({horizontalAlignment: 'center'})
    ws.column('I').style({horizontalAlignment: 'center'})
    ws.column('J').style({horizontalAlignment: 'center'})
    ws.column('K').style({horizontalAlignment: 'center'})
    ws.column('L').style({horizontalAlignment: 'center'})
    ws.column('B').width(20)
    ws.column('C').width(25)
    ws.column('D').width(15)
    ws.column('E').width(12)
    ws.column('F').width(20)
    ws.column('G').width(15)
    ws.column('I').width(15)
    ws.column('K').width(15)



    const styleRange = 'A1:L' + (data.length + (startDataLine - 1));
    const range = 'A'+startDataLine +':L' + (data.length + startDataLine);
    //Set Data
    ws.range(range).value(data)
    ws.range(styleRange).style({border:{
        style: 'thin', 
        color: '000033',
        borders:{
            left: true, 
            right: true, 
            top: true, 
            bottom: true}
        }, 
    })
    ws.range('A1:L2').style({border:{
        style: 'thin', 
        color: 'ffff00',
        borders:{
            left: true, 
            right: true, 
            top: true, 
            bottom: true
        }   
        }, 
    })
    const range1 = 'A'+startDataLine +':L' + (data.length + startDataLine -1);
    ws.range(range1).forEach((val, key)=> {
        if((key+1)%2 === 0) {
            val.style('fill', {
                type: 'solid',
                color:  'fff2e6'
            });
        }
    })
    ws.usedRange().style({fontFamily: 'Arial'})
    ws.range(styleRange).style({fontSize: '12'})

    ws.rightToLeft(true)
    ws.freezePanes('A3')

    let blob = await wb.outputAsync();
    saveAs(blob, 'SiteUsers.xlsx');
}
export default exportUsersToExcel;