import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, CheckedIcon, UncheckedIcon } from './custom-checkbox.styles';

    
    const Checkbox = ({ className, checked, ...props }) => (
        <CheckboxContainer className={className}>
            <HiddenCheckbox checked={checked} {...props} />
            <StyledCheckbox checked={checked}>
                <CheckedIcon viewBox='0 0 24 24'>
                    <polyline points='20 6 9 17 4 12' />
                </CheckedIcon>

                <UncheckedIcon viewBox='0 0 24 24'>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
                </UncheckedIcon>             
            </StyledCheckbox>
        </CheckboxContainer>
    );


export default Checkbox;