import { useState, useEffect, useRef } from 'react';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReactTabulator } from 'react-tabulator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import exportUsageToExcel from '../../util/excel-user-usage-report';
import { sendGetRequest } from '../../api/api';
import { selectSiteUser } from '../../redux/site-users/site-users.selectors';
import { selectUserAuth } from '../../redux/user/user.selectors';
import { UserUsageLogContainer, 
         NotFoundDiv,
         } from './user-usage-log.styles';
import './user-usage-log.styles.css'

const UserUsageLog = ({ siteUser, auth }) => {
    const [siteUserUsage, setSiteUserUsage] = useState([]);
    const usageTableRef=useRef(null);
    
    const handleGetUsage = async ()=> {
        try{
            const usage = await sendGetRequest(`/admin/usage/${siteUser.UserID}`,{},{authorization: auth});
            return usage;

        }catch(err) {
            throw new Error(err);
        }
    };

    const handleExport = () => {
        const headers = [
            {value: 'תאריך כניסה', key: 'A'},
            {value: 'יום', key: 'B'},
            {value: 'שעה', key: 'C'},
            {value: 'תאריך יציאה', key: 'D'},
            {value: 'יום', key: 'E'},
            {value: 'שעה', key: 'F'},
            {value: 'סה"כ', key: 'G'},
        ];    
        const data = usageTableRef.current.table.getData('active');
        const fixedData = data.map(obj => {
            delete obj.userId
            delete obj.logId
            delete obj.signIn
            delete obj.signOut
            return obj
        })

        let parsedData = []
        fixedData.map(obj => {
            let row = []
            row.push(
                obj.signInDate,
                obj.signInDay,
                obj.signInTime,
                obj.signOutDate,
                obj.signOutDay,
                obj.signOutTime,
                obj.signTime
            )
            parsedData.push(row)
            return obj
        })

        const filters = usageTableRef.current.table.getFilters(true);
        exportUsageToExcel(parsedData,headers,filters,siteUser.fullName)
    };

    const WEEKDAYS = ['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת'];
    const tableData = siteUserUsage.map(item => {
        item.signInTime = new Date(item.signIn).toLocaleTimeString('en-gb',{timeZone: 'UTC'});
        item.signInDay = WEEKDAYS[new Date(item.signIn).getUTCDay()] ;
        item.signInDate = new Date(item.signIn).toLocaleDateString('en-GB', {timeZone: 'UTC'});
        item.signOutTime = new Date(item.signOut).toLocaleTimeString('en-gb',{timeZone: 'UTC'})
        item.signOutDay = WEEKDAYS[new Date(item.signOut).getUTCDay()];
        item.signOutDate = new Date(item.signOut).toLocaleDateString('en-gb', {timeZone: 'UTC'})
        return item;
    });
    
    const tableColumns = [
        {title: 'ת.כניסה', field: 'signInDate', width: 105, hozAlign: 'center',
         headerFilter: 'input', 
         headerFilterPlaceholder: 'חיפוש לפי ת.כניסה'},
        {title: 'יום', field: 'signInDay', width: 70, hozAlign: 'center',
         headerFilter:"select", 
         headerFilterParams:{values:true}, headerFilterPlaceholder: 'חיפוש לפי יום'},
        {title: 'שעה', field: 'signInTime', width: 100, hozAlign: 'center'},
        {title: 'ת.יציאה', field: 'signOutDate', width: 101, hozAlign: 'center',
        headerFilter: 'input', 
        headerFilterPlaceholder: 'חיפוש לפי ת.יציאה'},
        {title: 'יום', field: 'signOutDay', width: 71, hozAlign: 'center',
        headerFilter:"select", 
        headerFilterParams:{values:true}, headerFilterPlaceholder: 'חיפוש לפי יום'},
        {title: 'שעה', field: 'signOutTime', width: 100, hozAlign: 'center'},
        {title: 'שהות', field: 'signTime', width: 100, hozAlign: 'center'},
    ];
    const tableOptions = {
        dataFiltered: function(filters, rows){

        },
        renderComplete: function() {
            this.setLocale('he-il');
         },
        downloadReady: (fileContents, blob) => {
            return blob
        },
        textDirection: 'rtl',
        resizableColumns:false,
        pagination: 'local',
        paginationSize: 15,
        paginationButtonCount: 3,
        responsiveLayout: 'hide',
        langs: {
            "he-il": {
                "pagination" : {
                    "first": "ראשון",
                    "last": "אחרון",
                    "next": "הבא",
                    "prev": "הקודם"
                }
            }
        }
    };
    useEffect(()=> {
        if(JSON.stringify(siteUser) !== '{}') {
            handleGetUsage().then(usage => {
                if(usage){
                    setSiteUserUsage([...usage])
                }
        })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[siteUser]);
    
    // const { fullName } = siteUser;

    return (
        <UserUsageLogContainer>
        {
            (siteUser.UserID !== undefined && siteUserUsage.length > 0) && 
            <UserUsageLogContainer>
            <div >
            <div className='table-actions'>
                <div className='table-action' onClick={handleExport} > הורדה לאקסל<span style={{marginLeft:'10px'}}><FontAwesomeIcon icon={faFileDownload} /></span></div>
            </div>
                <ReactTabulator
                    ref={usageTableRef}
                    id='usageTable'
                    data={tableData}
                    height='60vh'
                    columns={tableColumns}
                    layout='fitColumns'
                    options={tableOptions}
                    tooltips={true}
                    tooltipsHeader={true}
                />
            </div>
            </UserUsageLogContainer>
        }
        {
             (siteUser.UserID === undefined || siteUserUsage.length === 0) &&   
            <NotFoundDiv>
            <h3>לא נמצאו נתונים להצגה</h3>
            </NotFoundDiv>
        }
        </UserUsageLogContainer>
    );
};


const mapStateToProps = createStructuredSelector({
    siteUser: selectSiteUser,
    auth: selectUserAuth
})

export default connect(mapStateToProps)(UserUsageLog);