import React from 'react';
import { connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import { sendPostRequest } from '../../api/api';
import { selectUserAuth } from '../../redux/user/user.selectors';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import './select-customer.styles.scss'

const SelectCustomer = ({ auth, optionValue, setOptionValue, options, label  }) => {
    const loadOptions = (inputValue) => {
        return sendPostRequest('/admin/search/customer',{ searchTerm: inputValue },{ authorization: auth })
        .then(data => data)
    };

    const styles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: 'bold',
            color: state.isFocused ? '#3FB449' : 'black',
            backgroundColor: state.isFocused ? 'black' : 'white',
            alignSelf: 'right',
            width: '100%',
            borderBottom: '1px dotted gray'

        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',

        }),
        control: (provided, state) => ({
            ...provided,
            width: '100%',
            background: "white",
            borderRadius: 0,
            boxShadow: 'none',
            border: 'unset',
            borderBottom: '1px solid gray',
            height: 33,
            minHeight:30,
            fontSize: 18,
            fontWeight: 'normal',
            marginTop: 30,
            marginBottom: 0,
            padding: 0,
            paddingLeft: 10,
            '&:hover': { borderColor: 'gray'  }
        }),
        input: (provided, state) => ({
            ...provided,
            marginRight: 0,
            height: 25,
        }),
        menu: (provided) => ({
            ...provided,
            width: '100%',
            borderRadius: 0,
            marginTop: 0,
            textAlign: 'right',
        }),
        valueContainer: (provided,state) => ({
            ...provided,
            padding: 0,
            right: 0,
            top:0,
            height: '100%',
            margin: 0,

        }),
        indicatorContainer: (provided)=>({
            ...provided,
            height: '100%',
            border: '1px solid red',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            padding: 0
        }),
        placeholder: (provided, state)=> ({
            ...provided,
        })
    }

    const DropdownIndicator = props => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <FontAwesomeIcon icon={faAngleDown}/>
            </components.DropdownIndicator>
          )
        );
      }
    return(
            options 
        ?   
            <div style={{position: 'relative'}}>
            <div className='select-customer-label'>{label}</div>
            <Select 
            components={{DropdownIndicator, IndicatorSeparator: ()=> null}}
            options={options}
            onChange={setOptionValue}
            styles={styles}
            isRtl={true}
            isClearable={true}
            placeholder= {label}
            />
            </div>
        :      
            <div style={{position: 'relative'}}>
            <div className='select-customer-label'>{label}</div>
            <AsyncSelect 
            value={optionValue}
            components={{DropdownIndicator, IndicatorSeparator: ()=> null}}
            getOptionLabel={e => e.label}
            getOptionValue={e => e.value}
            loadOptions={loadOptions}
            onChange={setOptionValue}
            styles={styles}
            isRtl={true}
            isClearable={true}
            placeholder= 'לקוח'
            noOptionsMessage={()=> 'נא להקיש את שם הלקוח'}
            isSearchable={ optionValue ? (!optionValue.label ? true : false) : true }
            />
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    auth: selectUserAuth
});
export default connect(mapStateToProps)(SelectCustomer);