import { sendGetRequest, sendPostRequest, sendDeleteRequest, sendPutRequest } from '../../api/api';
import postsActionTypes from './posts.types';

export const startGetPosts = () => {
    return async (dispatch, getState) => {
        const auth = getState().user.currentUser.auth;
        try {
            dispatch({type: postsActionTypes.GET_POSTS_START});
            const posts = await sendGetRequest('/admin/posts', {}, {authorization: auth});
            if(!posts) {
                throw new Error('כשלון בשליפת הנתונים');
            }
            dispatch({type: postsActionTypes.GET_POSTS_SUCCESS, payload: posts})
            return 'done';
        } catch(error) {
            dispatch({type: postsActionTypes.GET_POSTS_FAILURE, payload: error.message});
        }
    };
};


export const selectPost = (post) => ({
    type: postsActionTypes.SET_CURRENT_POST,
    payload: post
});

export const clearPostsState = ()=>({
    type: postsActionTypes.CLEAR_POSTS_STATE
});

export const startUpdatePost = (postId, postUpdates) => {
    return async (dispatch, getState) => {
        const auth = getState().user.currentUser.auth;
        try {   
            dispatch({type: postsActionTypes.UPDATE_POST_START});
            const updatedPost = await sendPostRequest(`/admin/posts/${postId}`, { ...postUpdates }, { authorization: auth});
            if(!updatedPost) {
                throw new Error('Problem updating post')
            }
            dispatch({type: postsActionTypes.UPDATE_POST_SUCCESS, payload: updatedPost});
            return 'done';
        }catch(error) {
            dispatch({type: postsActionTypes.UPDATE_POST_FAILURE, payload: error.message});
        };
    };
};

export const startDeletePost = (postId) => {
    return async (dispatch, getState) => {
        const auth = getState().user.currentUser.auth;
        try {   
            dispatch({type: postsActionTypes.DELETE_POST_START});
            const { success } = await sendDeleteRequest(`/admin/posts/${postId}`, {}, {authorization: auth})
            if(!success) {
                throw new Error('Problem deleting post');
            }
            dispatch({type: postsActionTypes.DELETE_POST_SUCCESS, payload: postId});
            return 'done';

        }catch(error) {
            dispatch({type: postsActionTypes.DELETE_POST_FAILURE, payload: error.message});
        }

    };
};

export const startAddPost = (post) => {
    return async (dispatch, getState) => {
        try {
            dispatch({type: postsActionTypes.ADD_POST_START});
            const auth = getState().user.currentUser.auth;
            const userId = getState().user.currentUser.UserID;
            post.createdBy = userId;
            const newPost = await sendPostRequest('/admin/posts',{...post},{authorization: auth});
            if(!newPost){
                throw new Error('Problem creating new post');
            }
            dispatch({type: postsActionTypes.ADD_POST_SUCCESS, payload: newPost});
            return 'done'
        }catch(error) {
            dispatch({type: postsActionTypes.ADD_POST_FAILURE, payload: error.message});            ;
        };


    };
};

export const clearPostsError = () =>({
    type: postsActionTypes.CLEAR_POSTS_ERROR
});