const siteUsersActionTypes = {
    GET_SITE_USERS_START: 'GET_SITE_USERS_START',
    GET_SITE_USERS_SUCCESS: 'GET_SITE_USERS_SUCCESS',
    GET_SITE_USERS_FAILURE: 'GET_SITE_USERS_FAILURE',
    CREATE_USER_START: 'CREATE_USER_START',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
    SELECT_SITE_USER: 'SELECT_SITE_USER',
    CLEAR_SITE_USER: 'CLEAR_SITE_USER',
    CLEAR_SITE_USERS_STATE: 'CLEAR_SITE_USERS_STATE',
    UPDATE_SITE_USER_START: 'UPDATE_SITE_USER_START',
    UPDATE_SITE_USER_SUCCESS: 'UPDATE_SITE_USER_SUCCESS',
    UPDATE_SITE_USER_FAILURE: 'UPDATE_SITE_USER_FAILURE',
    CLEAR_UPDATED: 'CLEAR_UPDATED',
    SET_INITIAL_LOAD: 'SET_INITIAL_LOAD',
    CLEAR_INITIAL_LOAD: 'CLEAR_INITIAL_LOAD',
    CLEAR_SITE_USERS_ERROR: 'CLEAR_SITE_USERS_ERROR',
    DELETE_SITE_USER_START: 'DELETE_SITE_USER_START',
    DELETE_SITE_USER_SUCCESS: 'DELETE_SITE_USER_SUCCESS',
    DELETE_SITE_USER_FAILURE: 'DELETE_SITE_USER_FAILURE',
    CLEAR_POSTS_ERROR: 'CLEAR_POSTS_ERROR',
    CHANGE_SITE_USER_PASSWORD_START: 'CHANGE_SITE_USER_PASSWORD_START',
    CHANGE_SITE_USER_PASSWORD_SUCCESS: 'CHANGE_SITE_USER_PASSWORD_SUCCESS',
    CHANGE_SITE_USER_PASSWORD_FAILURE: 'CHANGE_SITE_USER_PASSWORD_FAILURE'
}

export default siteUsersActionTypes;