import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Spinner from '../spinner/spinner.component';
import PostsTable from './posts-table.table';

import { startGetPosts } from '../../redux/posts/posts-actions';
import { selectPostsError, selectPostsIsFetching , selectPosts} from '../../redux/posts/posts.selectors';


const PostsContainer = (props) => {
    const { error, isFetching, startFetchingPosts, posts } = props;

    useEffect(()=> {
        if(posts.length === 0)
        startFetchingPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div>
        {isFetching && <Spinner />}
        { error && <div className='posts-error'>{error}</div>}
        { (!isFetching && !error)&& <PostsTable /> }
        </div>
    );

};

const mapStateToProps = createStructuredSelector({
    error: selectPostsError,
    isFetching: selectPostsIsFetching,
    posts: selectPosts
});

const mapDispatchToProps = dispatch => ({
    startFetchingPosts: ()=> dispatch(startGetPosts()),
});

export default connect(mapStateToProps,mapDispatchToProps)(PostsContainer);